@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: ballingerMonoMd;
  src: url(../assets/fonts/ballingerMono/BallingerMonoMedium.otf);
  font-display: swap;
}

@font-face {
  font-family: BallingerMono;
  src: url(../assets/fonts/ballingerMono/BallingerMonoRegular.otf);
  font-display: swap;
}

@font-face {
  font-family: HelveticaNeueLT-Pro-Light;
  src: url(../assets/fonts/helveticaNeue/HelveticaNeueLTPro-Lt.otf);
  font-display: swap;
}

@font-face {
  font-family: HelveticaNeueLT-Roman;
  src: url(../assets/fonts/helveticaNeue/HelveticaNeueLTPro-Roman.otf);
  font-display: swap;
}

@font-face {
  font-family: HelveticaNeueLT-Pro-Medium;
  src: url(../assets/fonts/helveticaNeue/Helvetica-Neue-LT-Pro-65-Medium.otf);
  font-display: swap;
}

@font-face {
  font-family: HelveticaNeueLT-Pro-Bd;
  src: url(../assets/fonts/helveticaNeue/HelveticaNeueLTPro-Bd.otf);
  font-display: swap;
}

@font-face {
  font-family: HelveticaNowText;
  src: url(../assets/fonts/helveticaNow/HelveticaNowText.otf);
  font-display: swap;
}

@font-face {
  font-family: HelveticaNowTextBold;
  src: url(../assets/fonts/helveticaNow/HelveticaNowTextBold.otf);
  font-display: swap;
}

@font-face {
  font-family: HelveticaNowTextLight;
  src: url(../assets/fonts/helveticaNow/HelveticaNowTextLight.otf);
  font-display: swap;
}

@font-face {
  font-family: HelveticaNowTextMedium;
  src: url(../assets/fonts/helveticaNow/HelveticaNowTextMedium.otf);
  font-display: swap;
}

@font-face {
  font-family: Suisse-worksItalic;
  src: url(../assets/fonts/suisse-works/SuisseWorks-BookItalic.otf);
  font-display: swap;
}

body {
  // font-family: Helvetica, Arial, Verdana, Tahoma, sans-serif;
  font-family: HelveticaNeueLT-Pro-Light, HelveticaNeueLT-Roman, 'sans-serif';
}

.animate__fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

.animate__animated {
  --animate-duration: 1s;
  --animate-delay: 1s;
  --animate-repeat: 1;

  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-duration: var(--animate-duration);
  animation-duration: var(--animate-duration);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.sitemap a {
  font-size: 20px;
  font-weight: bold;
}

#policyContent a,
.sitemap a {
  color: #4171df;
  border-bottom: 1px;
  text-decoration: underline;
  text-underline-offset: 2px;
}

#policyContent p {
  margin-bottom: 1rem;
}

.skip-to-content-link {
  position: absolute;
  height: 30px;
  background: #4171df;
  text-decoration: none;
  padding: 5px;
  color: white;
  top: -1000px;
  transform: translateY(-100%);
  transition: transform 0.3s;

  &:focus,
  &:active {
    transform: translateY(0%);
    z-index: 1000;
    left: 48px;
    top: 66px;
    width: auto;
    // @apply cursor-pointer border-0 bg-primary px-1 py-6 text-white font-bold uppercase w-max h-max;
  }
}

input[type='search' i]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-left: 10px;
  background: linear-gradient(
      45deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 43%,
      #000 45%,
      #000 55%,
      rgba(0, 0, 0, 0) 57%,
      rgba(0, 0, 0, 0) 100%
    ),
    linear-gradient(
      135deg,
      transparent 0%,
      transparent 43%,
      #000 45%,
      #000 55%,
      transparent 57%,
      transparent 100%
    );
}

#onetrust-consent-sdk #onetrust-accept-btn-handler,
#onetrust-banner-sdk #onetrust-reject-all-handler {
  background-color: #0072ce !important;
  border-color: #0072ce !important;
  color: #ffffff !important;
}

.carousel.carousel-slider {
  overflow: unset !important;

  .slider-wrapper {
    .slide {
      opacity: 0.3;
    }

    .selected {
      opacity: 1;
    }
  }

  .control-dots {
    // .dot {
    //   width: 15px;
    //   height: 15px;
    // }

    .selected {
      background-color: #4171df !important;
    }
  }

  .dp-left-arrow {
    left: 0em;
    background-color: #4171df;
    border: none;
    position: absolute;
    top: 45%;
    bottom: auto;
    padding: 0.4em;
    border-radius: 50%;
    z-index: 2;

    @media (max-width: 1023px) {
      top: 45%;
    }

    @media (min-width: 1400px) {
      left: 0.5em;
    }

    @media (min-width: 1600px) {
      left: 1em;
    }
  }

  .dp-right-arrow {
    right: 0em;
    background-color: #4171df;
    border: none;
    position: absolute;
    top: 42%;
    bottom: auto;
    padding: 0.4em;
    border-radius: 50%;
    z-index: 2;

    // @media (max-width: 1023px) {
    //   top: 42%;
    // }

    @media (min-width: 1400px) {
      right: 0.5em;
    }

    @media (min-width: 1600px) {
      right: 1em;
    }
  }
}

footer h1,
h2,
h3,
h4 {
  text-transform: none !important;
}

.MicrosoftMap div:nth-child(2),
.MicrosoftMap .NavBar_Container {
  z-index: 0 !important;
}

.find-partner-results {
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
    border-radius: 10px;
  }

  &::-webkit-scrollbar {
    width: 8px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #000000;
    border-radius: 10px;
  }
}

.bg-circle {
  fill: #4271df;
}
.bg-track-circle {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #8eaaec;
}
.white-circle-track {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #ffffff;
}
.loader-animation {
  -webkit-animation: rotation 1s infinite linear;
  -moz-animation: rotation 1s infinite linear;
  -o-animation: rotation 1s infinite linear;
  animation: rotation 1s infinite linear;
  transform-origin: 50% 50%;
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
}
@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}
@-moz-keyframes rotation {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(359deg);
  }
}
@-o-keyframes rotation {
  from {
    -o-transform: rotate(0deg);
  }
  to {
    -o-transform: rotate(359deg);
  }
}

// PDP

.left {
  display: flex;
}
.left_1 {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.left_2 {
  width: 100%;
}
.left_2 img {
  width: 100%;
  object-fit: contain;
  // margin-top: 5%;
}
.img_wrap.active {
  border: 2px solid #000;
}
.img_wrap {
  cursor: pointer;
  margin-right: 20px;
  width: 70px;
}
.img_wrap img {
  width: 70px;
  height: 60px;
  object-fit: contain;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.pdp-plp-main-section {
  font-family: HelveticaNeueLT-Pro-Light, HelveticaNeueLT-Roman, 'sans-serif';
  letter-spacing: 0.5px;
}
.pdp-plp-main-section button {
  font-family: 'BallingerMono';
}
.font-helveticaNeueLTBd,
.font-HelveticaNeueLT-Bd,
.font-helvaticaFont {
  font-family: HelveticaNeueLT-Pro-Light;
}
.font-helveticaNeueLTRoman {
  font-family: HelveticaNeueLT-Roman;
}
.pdp-plp-main-section h1 {
  font-weight: 700;
}
.pdp-plp-main-section img,
.pdp-plp-main-section svg,
.pdp-plp-main-section video,
.pdp-plp-main-section canvas,
.pdp-plp-main-section audio,
.pdp-plp-main-section iframe,
.pdp-plp-main-section embed,
.pdp-plp-main-section object {
  display: inline !important;
  // height: inherit !important;
}
.plp-image-inline img {
  display: inline !important;
}
details,
details summary {
  padding-left: 0;
  background-image: none;
  //-webkit-appearance: none;
  list-style: none;
}
details > summary::-webkit-details-marker {
  display: none;
}
.hero-v2-carousel {
  .carousel.carousel-slider {
    .control-dots {
      .dot {
        width: 15px;
        height: 15px;
        opacity: 0.3;
        &:hover {
          background-color: #ffffff !important;
          opacity: 1;
        }
      }
      .selected {
        background-color: #ffffff !important;
        opacity: 1;
      }
      bottom: 10px;
      background: transparent;
      @media screen and (max-width: 1023px) {
        top: 230px !important;
        position: absolute;
        height: 25px;
      }
      @media only screen and (min-device-width: 1022px) and (max-device-width: 1023px) {
        top: 230px !important;
        position: absolute;
        height: 25px;
      }
    }
    height: 100%;
    .slider-wrapper {
      height: auto;

      .slider {
        margin: 0px;
        padding: 0px;
        height: 100%;
        width: 100%;
        .slide {
          margin: 0px;
          padding: 0px;
          height: 100%;
          width: 100%;
        }
        .slide img {
          @media (max-width: 1023px) {
            height: 280px !important;
          }
        }
      }
    }
  }
}

.centered-carousel {
  .carousel.carousel-slider {
    .control-dots {
      bottom: -40px;
    }
    .dp-left-arrow,
    .dp-right-arrow {
      background-color: #4171df;
      border-radius: 50%;
    }
  }
}

.carousel.carousel-slider {
  overflow: unset !important;
  .slider-wrapper {
    .slide {
      opacity: 0.3;
    }
    .selected {
      opacity: 1;
    }
  }

  .dp-left-arrow {
    left: 0em;
    background-color: transparent;
    border: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 0.4em;
    z-index: 2;
    @media (min-width: 1400px) {
      left: 0.5em;
    }
    @media (min-width: 1600px) {
      left: 1em;
    }
    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
      visibility: hidden;
    }
    background: none;
    cursor: pointer;
  }
  .dp-right-arrow {
    right: 0em;
    background-color: transparent;
    border: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 0.4em;
    z-index: 2;
    @media (min-width: 1400px) {
      right: 0.5em;
    }
    @media (min-width: 1600px) {
      right: 1em;
    }
    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
      visibility: hidden;
    }
    background: none;
    cursor: pointer;
  }
}
.dp-truncate-line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  //  width: 250px;
  overflow: hidden;
}

.rich-text {
  i {
    font-family: 'Suisse';
  }
  p > a > b {
    font-weight: normal;
  }
}

.img-container span span {
  padding-top: 100% !important;
  box-sizing: border-box;
}
.he-tab-section-wrapper ol,
.he-tab-section-wrapper ul,
.he-tab-section-wrapper menu {
  list-style: unset !important;
  padding-left: 40px;
}
//Added custom-hws-default-style css for overriding tailwind css style
.custom-hws-default-style {
  h1 {
    display: block;
    font-size: 2em;
    margin-top: 0.67em;
    margin-bottom: 0.67em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
    line-height: 2.5rem;
  }
  h2 {
    display: block;
    font-size: 1.5em;
    margin-top: 0.83em;
    margin-bottom: 0.83em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
    line-height: 2rem;
  }
  h3 {
    display: block;
    font-size: 1.17em;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
    line-height: 1.5rem;
    @media screen and (max-width: 640px) {
      font-size: 20px;
    }
  }
  h4 {
    display: block;
    font-size: 1em;
    margin-top: 1.33em;
    margin-bottom: 1.33em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
    line-height: 2rem;
  }
  h5 {
    display: block;
    font-size: 0.83em;
    margin-top: 1.67em;
    margin-bottom: 1.67em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
    line-height: 1rem;
  }
  h6 {
    display: block;
    font-size: 0.67em;
    margin-top: 2.33em;
    margin-bottom: 2.33em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
    line-height: 1rem;
  }
  ul {
    display: block;
    list-style-type: disc;
    margin-top: 1em;
    margin-bottom: 1 em;
    margin-left: 0;
    margin-right: 0;
    padding-left: 40px;
  }
  ol {
    display: block;
    list-style-type: decimal;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
    padding-left: 40px;
  }
  li {
    display: list-item;
  }
  p {
    display: block;
    // margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
  }
  dl {
    display: block;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
  }
  dd {
    display: block;
    margin-left: 40px;
  }
  hr {
    display: block;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    margin-left: auto;
    margin-right: auto;
    border-style: inset;
    border-width: 1px;
  }
  pre {
    display: block;
    font-family: monospace;
    white-space: pre;
    margin: 1em 0;
  }
  blockquote {
    display: block;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-right: 40px;
    border-left: 6px solid #e7ebee;
    padding-left: 30px;
    line-height: 25px;
  }
}
.he-header-search-input-box {
  @media screen and (min-width: 1681px) and (max-width: 1980px) {
    width: 850px;
  }
  @media screen and (min-width: 1481px) and (max-width: 1680px) {
    width: 720px;
  }
  @media screen and (min-width: 1281px) and (max-width: 1481px) {
    width: 550px;
  }
  @media screen and (min-width: 1124px) and (max-width: 1280px) {
    width: 530px;
  }
  @media screen and (min-width: 1024px) and (max-width: 1123px) {
    width: 550px;
  }
  width: 750px;
  height: 28px;
  border: none;
  border-bottom: 1px solid black;
  padding-left: 30px;
}
@media screen and (min-width: 640px) and (max-width: 1024px) {
  #custompaddingheadingsubtext section {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media screen and (max-width: 640px) {
  #custompaddingheadingsubtext section {
    padding-left: 0px;
    padding-right: 0px;
  }
}
.custom-scrollbar {
  scrollbar-color: #4171df #c8c8cd;
  scrollbar-width: thin;
  border-radius: 10px;
  -webkit-overflow-scrolling: auto;

  &::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: #c8c8cd; /* or add it to the track */
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #4171df;
    border: 4px solid transparent;
    border-radius: 10px;

    &:hover {
      background: #555;
    }
  }
}
.custom-mask-input input {
  color: #000000 !important;
}
